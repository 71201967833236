import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ScannedItems = ({ items, onDelete }) => {
  const classes = useStyles();

  const handleDelete = (item) => {
    onDelete(item);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>UPC / Barcode</TableCell>
            <TableCell>Sort</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell>Quality</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {item.upc}
              </TableCell>
              <TableCell>{item.sort}</TableCell>
              <TableCell align="right">{item.quantity}</TableCell>
              <TableCell>{item.quality}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ScannedItems;
