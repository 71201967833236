import React, { useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

const ScanLabelInput = ({ onChange, inputRef, ...props }) => {
  const [scanLabel, setScanLabel] = useState('');
  const classes = useStyles();

  const handleChange = (event) => {
    setScanLabel(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') onChange(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <TextField
        inputRef={inputRef}
        id="scan-label-input"
        label="Scan label"
        helperText="Please scan the pallet label or type in the pallet number from receiving"
        fullWidth
        margin="normal"
        value={scanLabel}
        name="scanLabel"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        {...props}
      />
    </FormControl>
  );
};

export default ScanLabelInput;
