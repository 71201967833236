import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  time: {
    display: 'flex',
    alignItems: 'baseline',
  },
  meridiem: {
    marginLeft: 8,
  },
});

const DateTimeWidget = () => {
  const [time, setTime] = useState(DateTime.local());

  useEffect(() => {
    const handle = setInterval(() => {
      const now = DateTime.local();
      // Compare times down to the minute, if this has not changed then we
      // do not update.
      if (time.toFormat('ff') !== now.toFormat('ff')) {
        setTime(now);
      }
    }, 1000);

    return () => {
      clearInterval(handle);
    };
  });

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h5" component="div" color="textSecondary">
        {time.toFormat('EEEE, MMMM d')}
      </Typography>
      <Typography
        variant="h1"
        component="div"
        color="textSecondary"
        className={classes.time}
      >
        {time.toFormat('h:mm')}
        <Typography
          variant="h4"
          component="span"
          color="inherit"
          className={classes.meridiem}
        >
          {time.toFormat('a')}
        </Typography>
      </Typography>
    </React.Fragment>
  );
};

export default DateTimeWidget;
