import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ScanIcon from '@material-ui/icons/CropFree';
import DotIcon from './DotIcon';
import SettingsIcon from '@material-ui/icons/Settings';

export const primary = [
  {
    icon: <DashboardIcon />,
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    icon: <ScanIcon />,
    label: 'Scan',
    url: '/scan',
  },
  {
    icon: <AccountCircleIcon />,
    label: 'People',
  },
];

export const bookmarks = [
  {
    icon: <DotIcon color="#73D500" />,
    label: 'Bookmark 1',
  },
  {
    icon: <DotIcon color="#FF4A7A" />,
    label: 'Bookmark 2',
  },
  {
    icon: <DotIcon color="#C27AEB" />,
    label: 'Bookmark 3',
  },
  {
    icon: <DotIcon color="#FFDD00" />,
    label: 'Bookmark 4',
  },
  {
    icon: <AddIcon />,
    label: 'Add Bookmark',
  },
];

export const secondary = [
  {
    icon: <SettingsIcon />,
    label: 'Settings',
    url: '/settings',
  },
];
