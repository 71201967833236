import React, { useState, useRef } from 'react';
import { Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Page from '../../components/Page';
import CustomerPicker from '../../components/CustomerPicker';

import ScanLabelInput from './ScanLabelInput';
import ScanItemForm from './ScanItemForm';
import ShipmentListButton from 'src/components/ShipmentListButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  paper: {
    height: 'auto',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  buttonRow: {
    display: 'inline-flex',
    position: 'absolute',
    right: '65px',
    marginTop: theme.spacing(4),
  },
  resetButton: {
    marginLeft: theme.spacing(1),
  },
}));

const ScanView = (props) => {
  const scanInputRef = useRef();
  const classes = useStyles();
  const [customer, setCustomer] = useState('');
  const [scanLabel, setScanLabel] = useState(null);

  const handleCustomerSelect = (customer) => {
    setTimeout(() => {
      scanInputRef.current.focus();
    }, 0);
    setCustomer(customer);
  };

  const handleScanLabelChange = (value) => {
    setScanLabel(value);
  };

  const handleReset = () => {
    setScanLabel(null);
    setCustomer('');
  };

  return (
    <Page title="Scan">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <CustomerPicker
            onSelect={handleCustomerSelect}
            selected={customer}
            disabled={!!scanLabel}
          />
          {customer && (
            <>
              <ScanLabelInput
                onChange={handleScanLabelChange}
                inputRef={scanInputRef}
                disabled={!!scanLabel}
              />
              <div className={classes.buttonRow}>
                <ShipmentListButton customerId={customer.id} />
                <Button
                  className={classes.resetButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleReset}
                  margin={10}
                >
                  Reset
                </Button>
              </div>
            </>
          )}
        </Paper>
        {scanLabel && (
          <Paper className={classes.paper}>
            <ScanItemForm customer={customer} scanLabel={scanLabel} />
          </Paper>
        )}
      </div>
    </Page>
  );
};

export default ScanView;
