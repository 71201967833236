import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import App from './components/App';
import theme from './theme';
import * as serviceWorker from './serviceWorker';

import AuthProvider from './components/AuthProvider';

const info = {
  date: process.env.REACT_APP_DATE,
  version: process.env.REACT_APP_VERSION,
  commit: process.env.REACT_APP_COMMIT,
  build: process.env.REACT_APP_BUILD_NUM,
};

const app = (window.app = {});
Object.entries(info).forEach(([key, value]) => {
  if (value) app[key] = value;
});

const root = (
  <Router>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  </Router>
);

const mountNode = document.getElementById('root');

ReactDOM.render(root, mountNode);

serviceWorker.unregister();
