import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Page from '../../components/Page';
import DateTimeWidget from './DateTimeWidget';

import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles({
  root: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: 24,
  },
  datetime: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: 1,
    height: '80%',
    background: `linear-gradient(
      to bottom, 
      rgba(0, 0, 0, 0) 0%, 
      rgba(0, 0, 0, 0.12) 30%, 
      rgba(0, 0, 0, 0.12) 70%, 
      rgba(0, 0, 0, 0)
    )`,
  },
});

const DashboardView = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Page title="Dashboard">
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h4">Hi {user ? user.firstname : ''}!</Typography>
          <Typography variant="h5" color="textSecondary">
            Welcome to your dashboard.
          </Typography>
        </div>
        <div className={classes.divider} />
        <div className={classes.datetime}>
          <DateTimeWidget />
        </div>
      </div>
    </Page>
  );
};

export default DashboardView;
