import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import LoadingState from '../LoadingState';
import DocumentTitle from './DocumentTitle';
import Header from './Header';
import Sidebar from './Sidebar';

const Main = styled('main')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: 'calc(100vh - 64)',
  marginLeft: 64,
  marginTop: 64,
  boxSizing: 'border-box',
});

const Page = ({ actions, children, loading, title, ...props }) => {
  if (loading) {
    return <LoadingState />;
  }

  return (
    <DocumentTitle title={title}>
      <Header />
      <Main>{children}</Main>
      <Sidebar />
    </DocumentTitle>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Page;
