import React from 'react';
import { styled } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightDispatcher from 'src/components/LightDispatcher';
import GlobalStyle from './GlobalStyle';
import Navigator from '../Navigator';

const Root = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  minHeight: '100%',
  background: 'inherit',
});

const App = () => (
  <Root>
    <GlobalStyle />
    <CssBaseline />
    <LightDispatcher />
    <Navigator />
  </Root>
);

export default App;
