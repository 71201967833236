import { useCallback, useEffect } from 'react';
import { dispatchScanToLight } from 'src/utils/scan';
import useConfig from 'src/hooks/useConfig';
import useSnackbar from 'src/hooks/useSnackbar';

import { objectRegexLookup } from './util';

let lightTimer;
let lightsOn = false;
// TODO: handle NOF light with catchall
const LightDispatcher = () => {
  const { config, error } = useConfig();
  const { showSnackbar } = useSnackbar();

  const clearLights = useCallback(() => {
    // CLR is a special command to clear the lights
    if (config) dispatchScanToLight(config.sortLightMap.__CLR);
  }, [config]);

  const teardown = useCallback(() => {
    clearTimeout(lightTimer);
    if (lightsOn) clearLights();
  }, [clearLights]);

  const handleSort = useCallback(
    ({ detail }) => {
      console.log('IN LIGHT DISPATCHER', detail.message);
      if (!config) {
        const message = `Configuration seems missing. Please relaunch the app.`;
        window.dispatchEvent(new CustomEvent('on-config-load-error'), {
          detail: message,
        });
        showSnackbar({
          message,
          variant: 'error',
        });
        return;
      }
      const command = objectRegexLookup(config.sortLightMap, detail.message);
      dispatchScanToLight(command);
      lightsOn = true;
      showSnackbar({
        message: `${detail.message} - ${command}`,
        variant: 'success',
      });

      if (config.lifetime)
        lightTimer = setTimeout(clearLights, config.lifetime);
    },
    [showSnackbar, clearLights, config],
  );

  useEffect(() => {
    window.addEventListener('on-sort', handleSort, false);
    return () => {
      window.removeEventListener('on-sort', handleSort, false);
      teardown();
    };
  });

  useEffect(() => {
    if (error) {
      window.dispatchEvent(new CustomEvent('on-config-load-error'), {
        detail: error.message,
      });
    }
  }, [error]);

  useEffect(() => {
    if (config) {
      window.dispatchEvent(new CustomEvent('on-config-load'));
    }
  }, [config]);

  return null;
};

export default LightDispatcher;
