import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    primary: blue,
    secondary: pink,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '0 24px',
      },
      label: {
        whiteSpace: 'nowrap',
      },
      sizeLarge: {
        padding: '0 32px',
        fontSize: '0.875rem',
        minHeight: 48,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 4,
        overflow: 'hidden',
      },
      underline: {
        '&:before': {
          content: 'none',
        },
      },
    },
  },
});

export default theme;
