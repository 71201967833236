import React, { useState } from 'react';
import { IconButton, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/icons/Assignment';

import ShipmentList from './ShipmentList';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
  },
}));

const ShipmentListButton = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return (
    <>
      <IconButton onClick={open}>
        <Icon />
      </IconButton>
      <Dialog
        maxWidth={false}
        open={isOpen}
        onEscapeKeyDown={close}
        onBackdropClick={close}
      >
        <div className={classes.wrapper}>
          <ShipmentList {...props} />
        </div>
      </Dialog>
    </>
  );
};

export default ShipmentListButton;
