import { useState, useEffect } from 'react';
import api from 'src/services/api';

const useConfig = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);

  const fetchData = async (params) => {
    setLoading(true);
    setError(null);

    try {
      const result = await api.config.list(params);
      setConfig(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({ _t: +new Date() });
    // eslint-disable-next-line
  }, []);

  // TODO: pull config when changes on event

  return {
    error,
    loading,
    config,
  };
};

export default useConfig;
