import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Divider,
  TextField,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import api from 'src/services/api';

import LoadingState from '../../components/LoadingState';
import ErrorState from '../../components/ErrorState';
import ScannedItems from './ScannedItems';
import useSnackbar from 'src/hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  scanWrap: {
    textAlign: 'center',
  },
}));

// FIXME: not so good.
let defaultValues = {
  quality: '',
  quantity: 1,
  upc: '',
};

let lightTimer;
// TODO: this component does a lot of things, probably should split

const ScanViewForm = ({ customer, scanLabel }) => {
  // workaround for loading audio
  // https://github.com/electron-react-boilerplate/electron-react-boilerplate/issues/1595
  const beep = document.getElementById('audio-beep');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { showSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(defaultValues);
  const [payload, setPayload] = useState([]);
  const barcodeInputRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    api.customers
      .shipment(customer.id, { scanLabel })
      .then(({ shipment, damageCodes, defaultDamageCode }) => {
        setData({ shipment, damageCodes });
        // FIXME: doesn't looks so good.
        defaultValues.quality = defaultDamageCode;
        setFormData((formData) => ({
          ...formData,
          quality: defaultDamageCode,
        }));
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [customer.id, scanLabel, setFormData]);

  const handlePayloadChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // temporary function to transform upc based on customer
  const transformUPC = (upc) => {
    // disabling === warning since id is string
    // eslint-disable-next-line
    if (customer.id == 8000 && upc.length > 11) {
      return [...upc].splice(0, 11).join('');
    }
    return upc;
  };

  const handleEnterKey = async (event) => {
    if (event.key === 'Enter' && formData.upc) {
      clearTimeout(lightTimer);
      beep.play();
      // TODO: Check if already scanned.
      const entry = { ...formData };

      // FIXME: Remove this hardcoded transform.
      // This should happen on server based on a configuration.
      entry.upc = transformUPC(entry.upc);

      const sortCodes = await api.customers.sortcodes(customer.id, {
        upc: entry.upc,
        damageId: entry.quality,
      });

      let item;
      if (sortCodes && sortCodes.length > 0) {
        item = sortCodes.find(({ upc }) => upc === entry.upc);
        entry.sort = item.primarySort;
        const customEvent = new CustomEvent('on-sort', {
          detail: { message: item.primarySort },
        });
        window.dispatchEvent(customEvent);
      } else {
        entry.sort = 'NOF';
        // TODO: handle NOF light with catchall
        showSnackbar({
          message: 'Not found',
          variant: 'error',
        });
      }

      setPayload([entry, ...payload]);
      setFormData(defaultValues);

      barcodeInputRef.current.focus();
    }
  };

  const handleDelete = (item) => {
    const without = payload.filter(({ upc }) => upc !== item.upc);
    setPayload([...without]);
    barcodeInputRef.current.focus();
  };

  if (loading) return <LoadingState />;

  if (error) return <ErrorState subtitle={error.message} />;

  return (
    <>
      <div className={classes.root}>
        <Typography variant="subtitle1">
          Inbound Bill of Lading: <strong>{data.shipment.billOfLading}</strong>
        </Typography>
        <Typography variant="subtitle1">
          Pallet #: <strong>{scanLabel}</strong>
        </Typography>
        <Typography variant="subtitle1">
          Description: <strong>{data.shipment.description}</strong>
        </Typography>
      </div>
      <Divider />
      <div className={classes.scanWrap}>
        <FormControl className={classes.formControl}>
          <TextField
            label="UPC / Barcode"
            margin="normal"
            variant="filled"
            name="upc"
            value={formData.upc}
            onChange={handlePayloadChange}
            inputRef={barcodeInputRef}
            onKeyPress={handleEnterKey}
            autoFocus
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Quantity"
            type="number"
            margin="normal"
            variant="filled"
            name="quantity"
            autoComplete="false"
            autoCorrect="false"
            value={formData.quantity}
            onChange={handlePayloadChange}
            onKeyPress={handleEnterKey}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Quality"
            select
            margin="normal"
            variant="filled"
            name="quality"
            value={formData.quality}
            onChange={handlePayloadChange}
            onKeyPress={handleEnterKey}
          >
            {data.damageCodes.map((code, index) => (
              <MenuItem key={index} value={code.id}>
                {code.description}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
      {payload.length > 0 && (
        <ScannedItems items={payload} onDelete={handleDelete} />
      )}
    </>
  );
};

export default ScanViewForm;
