import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import Settings from '../Settings';

import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: 'none',
    zIndex: 1100,
    backgroundColor: 'inherit',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    cursor: 'pointer',
    width: 32,
    height: 32,
    margin: -4,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Header(props) {
  const { user } = useAuth();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="inherit"
        classes={{ root: classes.root }}
      >
        <div className={classes.section} />
        <div className={classes.section}>
          <Toolbar>
            <IconButton>
              <SearchIcon />
            </IconButton>
            <IconButton>
              <NotificationsIcon />
            </IconButton>
            {user && (
              <Settings
                activator={
                  <IconButton>
                    <Avatar src={user.avatarUrl} className={classes.avatar} />
                  </IconButton>
                }
              />
            )}
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
}

export default Header;
